import i18next from "i18next";
import { initReactI18next } from "react-i18next";

const resources = {
  en: {
    translation: {
      crs: "CRS Score Calculator",
      pre: "Pre-Assessment Form",
      select: "Select...",
      maritalStatusQuestion: "What is your marital status?",
      spouseQuestion:
        "Is your spouse or common-law partner a citizen or permanent resident of Canada?",
      spouseMovingQuestion:
        "Will your spouse or common-law partner come with you to Canada?",
      ageQuestion: "How old are you?",
      educationStatusQuestion: "What is your level of education?",
      educationStatusElibilityLi1:
        "To get points for your foreign credential, you must obtain an Educational Credential Assessment (ECA) report. This report must verify that your foreign credential is valid and equivalent to a Canadian credential.",
      educationStatusElibilityLi2:
        "If you completed your studies at a private DLI (designated learning institution) in Canada and the program does not qualify for a post-graduation work permit, it is not considered 'Canadian education' for the purpose of earning additional points for Canadian education (refer to the following question for more details). However, your education may still be eligible for education points. For example, if you have a bachelor's degree from your home country and a one-year diploma from a private career college in Canada, you should select 'two or more certificates, diplomas, or degrees' as your education level.",
      CaEducationStatusQuestion:
        "Have you earned a Canadian degree, diploma or certificate?",
      CaEducationStatusElibility:
        "To confirm eligibility, please ensure the following criteria are met",
      CaEducationStatusElibilityLi1:
        "Your program of study must qualify for a post-graduation work permit.",
      CaEducationStatusElibilityLi2:
        "Courses in English or French as a Second Language should constitute less than half of your curriculum.",
      CaEducationStatusElibilityLi3:
        "Your education must not have been funded by a scholarship or grant that obligates you to apply your skills and knowledge in your home country post-graduation.",
      CaEducationStatusElibilityLi4:
        "Your institution of study must be located within Canada; studies at international branch campuses do not qualify.",
      CaEducationStatusElibilityLi5:
        "You must have been enrolled as a full-time student for a minimum of eight months, with the exception of those who completed their studies or training (either in full or partially) between March 2020 and August 2022.",
      CaEducationStatusElibilityLi6:
        "A physical presence in Canada for at least eight months is required, unless your study or training completion (in whole or part) falls between March 2020 and August 2022.",
      CaEducationLevelStatusQuestion:
        "Choose the best answer to describe your Canadian education.",
      languageStatusDescription:
        "Official Languages: English and French are Canada's official languages. Applicants are required to submit language test results that are less than two years old for all programs, regardless of whether English or French is their first language.",
      languageStatusWarning:
        "You may choose any language test and indicate the scores you have taken or anticipate taking.",
      languageQuestionFirst:
        "Which language test have you taken, or do you plan to take?",
      languageQuestionSpouse:
        "Which language test have you taken, or do you plan to take?",
      speaking: "Speaking",
      listening: "Listening",
      reading: "Reading",
      writing: "Writing",
      languageQuestionSecond:
        "Which language test have you taken, or are you considering, for your second foreign language?",
      CaWorkExperience: "Canadian Work Experience (TEER 0, 1, 2, 3)",
      CaWorkExperienceDescription:
        "In the last ten years, how many years of paid skilled work experience have you gained in Canada? This work experience does not have to be related to your field of study, can be in different NOC codes and does not have to be continuous. (Please do not include self-employment or student work)",
      CaWorkExperienceDescriptionList1:
        "Working thirty hours per week for 12 months equates to one year of full-time work, totaling 1,560 hours.",
      CaWorkExperienceDescriptionList2:
        "If you work fifteen hours per week for twenty-four months, it also corresponds to one year of full-time work, comprising 1,560 hours.",
      CaWorkExperienceDescriptionList3:
        "You have the flexibility to hold as many part-time jobs as necessary to fulfill this requirement.",
      CaWorkExperienceDescriptionList4:
        "If you work thirty hours per week for twelve months but across multiple jobs, it still amounts to one year of full-time employment, equaling 1,560 hours.",
      CaWorkExperienceDescriptionList5:
        "Any hours worked beyond thirty hours per week will not be considered towards meeting this requirement.",
      ForeignWorkExperience:
        "Foreign work experience (outside Canada work experience) (TEER 0, 1, 2, 3)",
      ForeignWorkExperienceQuestion:
        "In the last 10 years, how many total years of foreign skilled work experience do you have?",
      ForeignWorkExperienceElibility:
        "Eligibility Tip: If you do not have one year or more of Canadian work experience, at least one year of your foreign work experience must be continuous and in the same NOC code.",
      certificateStatusQuestion:
        "Do you have a certificate of qualification from a Canadian province or territory?",
      certificateStatusDescription:
        "A certificate of qualification shows that a person is qualified to work in a particular <span style='text-decoration: underline;'><b style='font-size: 12px;'>skilled trade</b></span> in Canada. This means they passed a certification test and meet all the requirements to do their job in that province or territory.",
      additionalPoint: "Additional Points",
      additionalPointDescriptionNeeded: "If needed",
      additionalPointDescription:
        "Do you have a valid job offer supported by a Labour Market Impact Assessment (LMIA)? ",
      additionalPointQualification: "Please ",
      additionalPointQualificationBold: "contact us",
      additionalPointQualificationCont:
        "for an assessment to verify if your job offer qualifies for points.",
      nocQuestion: "Which NOC TEER is the job offer?",
      nominationQuestion:
        "Do you have a nomination certificate from a province or territory?",
      lawPartnerStatusQuestion:
        "Do you or your spouse or common-law partner (if they will accompany you to Canada) have at least one brother or sister living in Canada who is a citizen or permanent resident and who is at least 18 years old?",
      spouseEducationQuestion:
        "What is the highest level of education for which your spouse or common-law partner's has?",
      spouseWorkStatus:
        "In the last ten years, how many years of skilled work experience in Canada does your spouse/common-law partner have?",
      spouseLanguageTestQuestion:
        "Did your spouse or common-law partner take a language test? If so, which one?",
      calculate: "Calculate Your Score",
      totalScore: "Total Score",
      detailShow: "Show Details",
      detailHide: "Hide Details",
      category: "Category",
      points: "Points",
      sectionA: "Section A",
      age: "Age",
      education: "Level of Education",
      caWork: "Canadian Work Experience",
      firstLanguage: "First Official Language Proficiency",
      firstLanguageTotal:
        "First Official Language Proficiency - Section A Details Total",
      secondLanguage: "Second Official Language Proficiency",
      secondLanguageTotalTable: "Second Official Language Proficiency",
      secondLanguageTotal:
        "Second Official Language Proficiency - Section A Details Total",
      sectionAtotal: "Section A - Total",
      sectionB: "Section B",
      spouseEducation: "Spouse's Level of Education",
      spouseWorkExperience: "Spouse's Canadian Work Experience",
      spouseLanguage: "Spouse's Official Language Proficiency",
      spouseLanguageTotal: "Spouse - Language Proficiency",
      sectionC: "Section C",
      educationAndLanguage: "Education Level and Language Level",
      workAndEducation: "Canadian Work Experience and Education Level",
      sectionCpart1: "Subtotal (Max. 50 Points)",
      foreignWorkAndLanguage: "Foreign Work Experience and Language Level",
      canadianAndForeign:
        "Foreign Work Experience and Canadian Work Experience",
      sectionCpart2: "Subtotal (Max. 50 Points)",
      qualificationCertificate: "Certificate of Qualification",
      sectionD: "Section D",
      sectionBtotal: "Section B - Total",
      sectionCtotal: "Section C - Total (Max. 100 Points)",
      sectionDtotal: "Section D - Total",
      sibling: "Brother or Sister Living in Canada",
      frenchProf: "French Language Proficiency",
      postSecondary: "Post Secondary Education in Canada",
      arrangedEmployment: "Arranged Employment",
      nomination: "Provincial Nomination",
      legalDisclaimerAlert: "Please agree to the terms to continue.",
      legalDisclaimerTitle: "Legal Disclaimer",
      legalDisclaimer1:
        "The CRS Calculator provided on this website is developed and operated by Northern Pathways Immigration Consulting Inc. for informational purposes only, based on the Comprehensive Ranking System (CRS) criteria outlined by Immigration, Refugees and Citizenship Canada (IRCC). This tool is not affiliated with or endorsed by IRCC. The aim is to offer users general guidance and a preliminary assessment of their potential CRS score.",
      legalDisclaimer2:
        "Please note that the official determination of CRS scores is solely conducted through the Express Entry system administered by IRCC. In the event of any discrepancies between the results generated by our calculator and those from the official Express Entry system, the latter shall prevail.",
      legalDisclaimer3:
        "The outcomes provided by our tool are not binding and should be considered as estimations only. Users are advised that the immigration rules, along with the CRS criteria, are subject to change, and Northern Pathways Immigration Consulting Inc. commits to updating the tool accordingly to reflect any changes in the Ministerial Instructions governing Express Entry.",
      legalDisclaimer4: "Our CRS Calculator",
      legalDisclaimer5:
        "is designed to provide you with an indicative CRS score, which is a critical factor in determining your position within the Express Entry pool. This tool will help you understand how your",
      legalDisclaimerLi1: "Age",
      legalDisclaimerLi2: "Education",
      legalDisclaimerLi3: "Language proficiency",
      legalDisclaimerLi4: "Work experience",
      legalDisclaimerLi5: "Other relevant factors",
      legalDisclaimer6:
        "contribute to your overall CRS score. By obtaining an estimated score through our calculator, you can better assess the strength of your Express Entry profile and explore ways to potentially enhance your ranking within the pool.",
      legalDisclaimer7: "This tool is suitable for individuals who",
      legalDisclaimerLi6:
        "Meet the eligibility criteria for at least one of the Express Entry programs,",
      legalDisclaimerLi7:
        "Have not yet created an Express Entry profile but wish to understand their potential CRS score,",
      legalDisclaimerLi8:
        "Have received an invitation to apply for permanent residence and are interested in evaluating how changes to their profile might influence their CRS score.",
      legalDisclaimer8:
        "To get an invitation to apply for permanent residence, candidates must have a CRS score that meets or exceeds the minimum score of their specific invitation round. Please be aware that cut-off scores can vary between rounds.",
      agreeTerms:
        "I confirm that I have read and accepted the legal disclaimer and information provided on this page.",
      agree: "Agree",
      callToAction:
        "At Northern Pathways Immigration Consulting, we guide individuals on their journey to Canadian permanent residence. Begin by completing our pre-assessment form to identify your potential eligibility for permanent residence pathways. If the assessment suggests you could be eligible, you’re invited to book a 1-hour consultation with us. In this session, we will provide personalized insights and strategies tailored to your specific situation. We’ll focus not only on understanding your options within the Express Entry system and ways to potentially enhance your CRS score but also explore the Provincial Nominee Programs as an alternative pathway to permanent residence. This comprehensive approach is your starting point towards navigating your future in Canada with informed clarity.",
      preAssessment: "Click Here For The Pre-Assessment Form",
      appointment: "Please note that we do not accept walk-in appointments.",
      appointment2: "Kindly ensure to book your consultation in advance.",
      copyright: "All rights reserved",
      expressEntryTitle: "Latest Three Express Entry Draws by Category",
      expressEntryDate: "Date",
      expressEntryInvitations: "Number of Invitations Issued",
      expressEntryCRS: "CRS Score of Lowest-ranked Candidate Invited",
      legalDisclaimerFswp:
        "The Federal Skilled Worker (FSW) Six Selection Factors Calculator provided on this website is developed and operated by Northern Pathways Immigration Consulting Inc. for informational purposes only, based on the Six Selection Factors criteria outlined by Immigration, Refugees and Citizenship Canada (IRCC). This tool is not affiliated with or endorsed by IRCC. The aim is to offer users general guidance and a preliminary assessment of their potential eligibility for FSW Program.",
      legalDisclaimerFswp2:
        "In the event of any discrepancies between the results generated by our calculator and those from the official Express Entry system, the latter shall prevail.",
      FSWPNavbar: "FSW Six Selection Factors Calculator",
      FSWPTitle: "FSW Six Selection Factors",
      FSWPTitle2: "Calculator",
      FSWPPopUpQuestion: "What is FSW Six Selection Factors Calculator?",
      FSWPPopUpTitle: "FSW Six Selection Factors Calculator",
      FSWPPopUpP1:
        "To be eligible for the Federal Skilled Worker (FSW) Program, you must meet the ",
      FSWPPopUpP1Link: "general eligibility criteria",
      FSWPPopUpP1Cont:
        "and achieve at least 67 points in the selection factors.",
      FSWPPopUpP2:
        "You can use our calculator to see if you meet the required points for the FSW Program.",
      FSWPPopUpP3: "The Federal Skilled Worker (FSW) program is",
      FSWPPopUpP3Bold:
        "one of the three programs under Canada's Express Entry system.",
      FSWPPopUpP3Cont:
        "The Six Selection Factors Calculator is essential for determining your eligibility for this program.",
      FSWPPopUpP4:
        "The calculator assesses potential immigrants across six key factors, requiring",
      FSWPPopUpP4Bold: "a minimum score of 67 out of 100",
      FSWPPopUpP4Cont: "to be eligible.",
      FSWPPopUpTitle2:
        "How Does the FSW Six Selection Factors Calculator Work?",
      FSWPPopUpP5: "The calculator evaluates the following factors:",
      FSWPPopUpLi1Title: "Language Proficiency (28 points):",
      FSWPPopUpLi1:
        "Your skills in English and/or French are critical for Canadian immigration.",
      FSWPPopUpLi2Title: "Education (25 points):",
      FSWPPopUpLi2:
        "Points are awarded based on your highest level of education.",
      FSWPPopUpLi3Title: "Work Experience (15 points):",
      FSWPPopUpLi3:
        "Your work experience is a significant factor, with up to 15 points available.",
      FSWPPopUpLi4Title: "Age (12 points):",
      FSWPPopUpLi4: "Younger applicants often receive higher points.",
      FSWPPopUpLi5Title: "Arranged Employment (10 points):",
      FSWPPopUpLi5:
        "Having a valid job offer from a Canadian employer increases your score.",
      FSWPPopUpLi6Title: "Adaptability (10 points):",
      FSWPPopUpLi6:
        "This factor assesses how well you and your spouse/common-law partner are likely to adapt to life in Canada.",
      FSWPPopUpP6:
        "Achieving 67 or more points and meeting the general eligibility criteria for the Federal Skilled Worker (FSW) Program allows you to create an Express Entry profile. ",
      FSWPPopUpP6Bold:
        "However, this does not guarantee an invitation to apply (ITA) for permanent residence.",
      FSWPPopUpP6Cont:
        "Your ranking in the pool is based on your Comprehensive Ranking System (CRS) score, which determines your chances of receiving an ITA.",
      FSWPPopUpP7:
        "To receive an ITA, your CRS score must meet or exceed the cut-off score for your specific invitation round. Please note that these cut-off scores can vary between rounds.",
      FSWPPopUpP8: "Use our ",
      FSWPPopUpP8Bold: "CRS Score Calculator",
      FSWPPopUpP8Cont:
        "to estimate your potential CRS score and better understand your chances of receiving an ITA.",
      FSWPLanguageNote: "Below CLB 7 - Not eligible to apply",
      FSWPWorkExperience: "Work Experience",
      FSWPWorkExperienceP1:
        "You can get points based on the duration of your full-time paid work experience (at least 30 hours per week, or the equivalent amount of part-time work [e.g. 15 hours per week for 24 months]) in a skilled occupation listed under Training, Education, Experience, and Responsibilities (TEER) categories 0, 1, 2, or 3 of the 2021 National Occupational Classification (NOC).",
      FSWPWorkExperienceLiP1:
        "To get selection factor points, your work experience can be:",
      FSWPWorkExperienceLi: "Either in Canada or abroad.",
      FSWPWorkExperienceLi2: "Gained while you were studying.",
      FSWPWorkExperienceLi3: "Obtained while being self-employed.",
      FSWPAdaptability: "Adaptability",
      FSWPAdaptabilityTotal: "Adaptability Total",
      FSWPEducation:
        "Have you completed at least 2 academic years of full-time study (in a minimum 2-year program) in Canada?",
      FSWPCanadianWorkExp: "Canadian Work Experience",
      FSWPCanadianWorkP1:
        "In a job categorized under TEER 0, 1, 2, or 3 of the NOC, and either holding a valid work permit or being authorized to work in Canada.",
      FSWPSpouseEducationP1:
        "Has your spouse or your partner completed at least 2 academic years of full-time study (in a minimum 2-year program) in Canada?",
      FSWPRelativesP1:
        "Do you or your spouse or common-law partner have a relative who is living in Canada, 18 years or older, and a Canadian citizen or permanent resident? This relative must be one of the following:",
      FSWPRelativesLi1: "Parent",
      FSWPRelativesLi2: "Grandparent",
      FSWPRelativesLi3: "Child",
      FSWPRelativesLi4: "Grandchild",
      FSWPRelativesLi5:
        "Your or your spouse’s sibling (child of your or your spouse’s parent)",
      FSWPRelativesLi6:
        "Your or your spouse’s aunt or uncle (by blood or marriage)",
      FSWPRelativesLi7: "Your or your spouse’s niece or nephew",
      expressEntrySubcategory: "Subcategory",
      FSWPGeneral: "General",
      FSWPGeneralTotal: "General - Total",
    },
  },
  tr: {
    translation: {
      crs: "Express Entry CRS Puanı Hesaplama Aracı",
      pre: "Ön Değerlendirme Formu",
      select: "Seç...",
      maritalStatusQuestion: "Medeni durumunuz nedir?",
      spouseQuestion:
        "Eşiniz veya partneriniz Kanada vatandaşı veya kalıcı oturum izni sahibi mi?",
      spouseMovingQuestion:
        "Eşiniz veya partneriniz sizinle Kanada'ya gelecek mi?",
      ageQuestion: "Kaç yaşındasınız?",
      educationStatusQuestion: "Eğitim seviyeniz nedir?",
      educationStatusElibilityLi1:
        "Kanada dışı eğitiminizden puan alabilmek için eğitiminizin Kanada denkliğini almanız gerekmektedir. Bu denkliğin eğitiminizin geçerli olduğunu doğrulaması ve eş değer olduğu Kanada eğitim seviyesini göstermesi gerekmektedir.",
      educationStatusElibilityLi2:
        "Kanada'da mezuniyet sonrası çalışma izni uygunluğu olmayan özel bir kolejde öğrenim gördüyseniz, Kanada eğitimi için ek puan almazsınız (bkz. Bir sonraki soru). Ancak bu eğitimden, eğitim seviyesi kategorisinde puan alabilirsiniz (okul DLI listesinde olmalı). Örneğin, kendi ülkenizden bir lisans dereceniz ve Kanada'daki özel bir kariyer kolejinden bir yıllık diplomanız varsa, eğitim seviyeniz olarak 'iki veya daha fazla sertifika, diploma veya derece' seçmelisiniz.",
      CaEducationStatusQuestion:
        "Kanada'da bir derece, diploma veya sertifika aldınız mı?",
      CaEducationStatusElibility:
        "Uygunluğunuzu belirlemek için lütfen aşağıdaki kriterlerin karşılandığından emin olun",
      CaEducationStatusElibilityLi1:
        "Öğrenim programınızın mezuniyet sonrası çalışma izni almaya uygun olması gerekir.",
      CaEducationStatusElibilityLi2:
        "İngilizce veya Fransızca dil dersleri programınızın müfredatının yarısından azını oluşturmalıdır.",
      CaEducationStatusElibilityLi3:
        "Eğitiminiz, mezuniyetten sonra becerilerinizi ve bilgilerinizi kendi ülkenizde uygulamanızı zorunlu kılan bir burs veya bağışla finanse edilmemiş olmalıdır.",
      CaEducationStatusElibilityLi4:
        "Okulunuz fiziksel olarak Kanada'da bulunmalıdır.",
      CaEducationStatusElibilityLi5:
        "En az sekiz ay boyunca tam zamanlı öğrenci olarak kayıtlı olmanız gerekir. Eğitimlerini Mart 2020 ile Ağustos 2022 tarihleri arasında (tamamen veya kısmen) tamamlayanlar hariç.",
      CaEducationStatusElibilityLi6:
        "Kanada'da en az sekiz ay boyunca fiziksel olarak bulunmuş olmanız gerekir.- Eğitimlerini Mart 2020 ile Ağustos 2022 tarihleri arasında (tamamen veya kısmen) tamamlayanlar hariç.",
      CaEducationLevelStatusQuestion:
        "Kanada eğitiminizi açıklayan cevabı seçiniz.",
      languageStatusDescription:
        "Resmi diller: Kanada'nın resmi dilleri İngilizce ve Fransızcadır. Ana diliniz İngilizce veya Fransızca olsa dahi, tüm programlar için iki yıldan daha eski olmayan dil sınavı sonuçlarını bildirmeniz gerekmektedir.",
      languageStatusWarning:
        "Aldığınız veya alabileceğinizi düşündüğünüz herhangi bir test ve test puanını seçebilirsiniz.",
      languageQuestionFirst:
        "İlk yabancı diliniz için hangi dil testine girdiniz ya da girmeyi düşünüyorsunuz?",
      languageQuestionSpouse:
        "Hangi dil testine girdiniz ya da girmeyi düşünüyorsunuz?",
      languageSubQuestion:
        "<span style='font-size: 12px;'><b><i>Aldığınız ya da alabileceğinizi düşündüğünüz sınav puanlarını seçin.</i></b></span>",
      speaking: "Konuşma",
      listening: "Dinleme",
      reading: "Okuma",
      writing: "Yazma",
      languageQuestionSecond:
        "İkinci yabancı diliniz için hangi dil testine girdiniz ya da girmeyi düşünüyorsunuz?",
      CaWorkExperience: "Kanada İş Deneyimi (TEER 0, 1, 2, 3)",
      CaWorkExperienceDescription:
        "Son on yıl içinde, Kanada'da ne kadar süreyle iş deneyimi kazandınız? Bu iş deneyiminin eğitim alanınızla ilgili olması gerekmiyor, farklı NOC kodlarında olabilir ve kesintisiz olmak zorunda değil. (Kendi işinizi yaptığınız zamanları veya öğrenci çalışmalarını dahil etmeyin)",
      CaWorkExperienceDescriptionList1:
        "Haftada otuz saat çalışmak 12 ay boyunca tam zamanlı bir yıl iş deneyimine eş değerdir ve toplam 1,560 saatlik bir süreyi kapsar.",
      CaWorkExperienceDescriptionList2:
        "Haftada on beş saat yirmi dört ay boyunca çalışmak, tam zamanlı bir yıl iş deneyimine denk gelir.",
      CaWorkExperienceDescriptionList3:
        "Bu gereksinimi karşılamak için ihtiyacınız olan kadar çok sayıda yarı zamanlı işte çalışma esnekliğiniz vardır.",
      CaWorkExperienceDescriptionList4:
        "Otuz saatlik haftalık çalışma süresini on iki ay boyunca birden fazla işte çalışarak da doldursanız, yine tam zamanlı bir yıl iş deneyimine eş değerdir.",
      CaWorkExperienceDescriptionList5:
        "Otuz saatlik haftalık çalışmanın ötesinde çalışılan saatler bir yıllık çalışma süresinin daha erken tamamlanmasını sağlamaz ve hesaplamaya katılmaz.",
      ForeignWorkExperience: "Kanada dışı iş deneyimi (TEER 0, 1, 2, 3)",
      ForeignWorkExperienceQuestion:
        "Son 10 yıl içinde kaç yıl Kanada dışı iş deneyiminiz var?",
      ForeignWorkExperienceElibility:
        "Uygunluk İpucu: Eğer bir yıl veya daha fazla Kanada iş deneyiminiz yoksa, Kanada dışı iş deneyiminizin en az bir yılı kesintisiz ve aynı NOC kodunda olmalıdır.",
      certificateStatusQuestion:
        "Kanada'da bir eyaletten yeterlilik sertifikasına sahip misiniz?",
      certificateStatusDescription:
        "Yeterlilik sertifikası, bir kişinin Kanada'da belirli bir <span style='text-decoration: underline;'><b style='font-size: 12px;'>nitelikli zanaat</b></span> kolunda çalışmaya yetkili olduğunu gösterir. Bu, bir sertifikasyon testini geçtikleri ve o eyalette çalışmak için tüm gereklilikleri karşıladıkları anlamına gelir.",
      additionalPoint: "Ek Puanlar",
      additionalPointDescriptionNeeded: "LMIA’in gerekli olmadığı durumlar",
      additionalPointDescription:
        "İşgücü Piyasası Etki Değerlendirmesi (LMIA) ile desteklenen geçerli bir iş teklifiniz var mı? ",
      additionalPointQualification:
        "Puan alabilecek bir iş teklifinizin olup olmadığını anlamak için ",
      additionalPointQualificationBold: "bizimle iletişime geçebilirsiniz.",
      additionalPointQualificationCont: "",
      nocQuestion: "İş teklifiniz hangi NOC TEER grubunda yer alıyor?",
      nominationQuestion: "Bir eyaletten adaylık sertifikanız bulunuyor mu?",
      lawPartnerStatusQuestion:
        "Sizin veya eşiniz ya da birlikte yaşadığınız partnerinizin (Kanada'ya sizinle birlikte gelecekse) Kanada'da yaşayan, Kanada vatandaşı ya da kalıcı oturum izni sahibi olan ve en az 18 yaşında olan bir kardeşi var mı?",
      spouseEducationQuestion:
        "Eşinizin veya birlikte yaşadığınız partnerinizin en yüksek eğitim seviyesi nedir?",
      spouseWorkStatus:
        "Son on yıl içinde, eşinizin/birlikte yaşadığınız partnerinizin Kanada'da kaç yıl nitelikli iş tecrübesi var?",
      spouseLanguageTestQuestion:
        "Eşiniz veya birlikte yaşadığınız partneriniz bir dil sınavına girdi mi? Eğer girdiyse, hangi sınav?",
      calculate: "Puan Hesapla",
      totalScore: "Toplam Puanınız",
      detailShow: "Detayları Göster",
      detailHide: "Detayları Gizle",
      category: "Kategori",
      points: "Puan",
      sectionA: "Bölüm A",
      age: "Yaş",
      education: "Eğitim Seviyesi",
      caWork: "Kanada İş Deneyimi",
      firstLanguage: "İlk Yabancı Dil Yeterliliği",
      firstLanguageTotalTable: "İlk Yabancı Dil Yeterliliği",
      firstLanguageTotal:
        "İlk Yabancı Dil Yeterliliği - Bölüm A Toplam Detaylar",
      secondLanguage: "İkinci Yabancı Dil Yeterliliği",
      secondLanguageTotalTable: "İkinci Yabancı Dil Yeterliliği",
      secondLanguageTotal:
        "İkinci Yabancı Dil Yeterliliği - Bölüm B Toplam Detaylar",
      sectionAtotal: "Bölüm A - Toplam",
      sectionB: "Bölüm B",
      spouseEducation: "Eş - Eğitim Seviyesi",
      spouseWorkExperience: "Eş - Kanada İş Deneyimi",
      spouseLanguage: "Eşinizin Yabancı Dil Yeterliliği",
      spouseLanguageTotal: "Eş - Yabancı Dil Yeterliliği",
      sectionC: "Bölüm C",
      educationAndLanguage: "Eğitim Seviyesi ve Dil Seviyesi",
      workAndEducation: "Kanada İş Tecrübesi ve Eğitim Seviyesi",
      sectionCpart1: "Ara Toplam (En fazla 50 puan)",
      foreignWorkAndLanguage: "Kanada Dışı İş Tecrübesi ve Dil Seviyesi",
      canadianAndForeign: "Kanada Dışı İş Tecrübesi ve Kanada İş Tecrübesi",
      sectionCpart2: "Ara Toplam (En fazla 50 puan)",
      qualificationCertificate: "Yeterlilik Sertifikası",
      sectionD: "Bölüm D",
      sectionBtotal: "Bölüm B - Toplam",
      sectionCtotal: "Bölüm C - Toplam (En fazla 100 puan)",
      sectionDtotal: "Bölüm D - Toplam",
      sibling: "Kanada'da Yaşayan Kardeş",
      frenchProf: "Fransızca Dil Yeterliliği",
      postSecondary: "Kanada'da Yüksek Öğrenim",
      arrangedEmployment: "İş Teklifi",
      nomination: "Eyalet Adaylığı",
      legalDisclaimerAlert: "Devam etmek için lütfen şartları kabul edin.",
      legalDisclaimerTitle: "Hukuki Sorumluluk Reddi",
      legalDisclaimer1:
        "Bu CRS Hesaplayıcı, Northern Pathways Immigration Consulting Inc. tarafından bilgilendirme amaçlı geliştirilmiştir ve Immigration, Refugees and Citizenship Canada (IRCC) tarafından belirlenen Comprehensive Ranking System (CRS) kriterlerine dayanmaktadır. Bu araç IRCC ile ilişkili veya IRCC tarafından onaylı değildir. Amacı, göçmen adaylarına potansiyel CRS puanları hakkında genel bir rehberlik ve ön değerlendirme sunmaktır.",
      legalDisclaimer2:
        "Lütfen resmi CRS puanlarının belirlenmesinin yalnızca IRCC tarafından yürütülen Express Entry sistemi aracılığıyla yapıldığını unutmayın. Hesaplayıcımız tarafından üretilen sonuçlar ile resmi Express Entry sisteminin sonuçları arasında herhangi bir farklılık olması durumunda, sistemin sonuçları geçerli olacaktır.",
      legalDisclaimer3:
        "Araç tarafından sağlanan sonuçlar bağlayıcı olmayıp yalnızca tahmini sonuç olarak değerlendirilmelidir. Kullanıcılar, göçmenlik kurallarının ve CRS kriterlerinin değişebileceğini ve Northern Pathways Immigration Consulting Inc. tarafından, Express Entry kurallarındaki herhangi bir değişikliği yansıtacak şekilde aracın zaman zaman güncelleneceğini bilmelidir.",
      legalDisclaimer4: "CRS Hesaplayıcımız",
      legalDisclaimer5:
        "Express Entry havuzundaki konumunuz için kritik bir faktör olan CRS puanınızı belirlemek üzere tasarlanmıştır. Bu araç, aşağıdakiler dahil olmak üzere profilinizin CRS puanına nasıl katkıda bulunduğunu anlamanıza yardımcı olacaktır",
      legalDisclaimerLi1: "Yaş",
      legalDisclaimerLi2: "Eğitim",
      legalDisclaimerLi3: "Dil yeterliliği",
      legalDisclaimerLi4: "İş deneyimi",
      legalDisclaimerLi5: "Diğer ilgili faktörler",
      legalDisclaimer6:
        "Hesaplayıcı aracılığıyla tahmini bir puan elde ederek, Express Entry profilinizin gücünü daha iyi değerlendirebilir ve havuzdaki sıralamanızı potansiyel olarak iyileştirebilecek yolları planlayabilirsiniz.",
      legalDisclaimer7: "Bu araç, aşağıdaki durumlarda olan kişiler içindir",
      legalDisclaimerLi6:
        "En az bir Express Entry programı için uygunluk kriterlerini karşılayan,",
      legalDisclaimerLi7:
        "Henüz bir Express Entry profili oluşturmamış ancak potansiyel CRS puanını anlamak isteyen,",
      legalDisclaimerLi8:
        "Kalıcı oturum başvurusu için davet almış ve profillerindeki değişikliklerin CRS puanları üzerindeki etkisini değerlendirmek isteyen kişiler.",
      legalDisclaimer8:
        "Kalıcı oturum başvurusu daveti alabilmek için, adayların CRS puanlarının davet turunun minimum puanından yüksek veya ona eşit olması gerekmektedir. Her turun minimum puanlarının farklılık gösterebileceğini unutmayın.",
      agreeTerms:
        "Bu sayfadaki hukuki sorumluluk reddini ve bilgileri okuduğumu ve kabul ettiğimi onaylıyorum.",
      agree: "Kabul Ediyorum",
      callToAction:
        "Northern Pathways Immigration Consulting olarak, bireylere Kanada göçmenlik yolculuklarında kılavuzluk ediyoruz. Potansiyel kalıcı oturum yolları için uygunluğunuzu belirlemek için ilk olarak web sitemizdeki ön değerlendirme formunu doldurabilirsiniz. Uygunluğu olabilecek adaylara 1 saatlik bir konsültasyon görüşmesi öneriyoruz. Bu görüşmede, profilinize yönelik kişiselleştirilmiş senaryolar ve stratejiler paylaşıyoruz. Express Entry sistemi içindeki seçeneklerinizi, CRS puanınızı potansiyel olarak nasıl iyileştirebileceğinizi ve alternatif olabilecek eyalet aday programlarını konuşuyoruz. Kanada’daki geleceğiniz için atacağınız bu önemli adımda buluşmak üzere.",
      preAssessment: "Ön Değerlendirme Formu İçin Tıklayınız",
      appointment: "Lütfen randevusuz gelenleri kabul etmediğimizi unutmayın.",
      appointment2:
        "Danışma için önceden rezervasyon yaptırdığınızdan emin olun.",
      copyright: "Tüm hakları saklıdır",
      expressEntryTitle: "Kategoriye Göre Son Üç Express Entry Çekilişi",
      expressEntryDate: "Çekiliş Tarihi",
      expressEntryInvitations: "Davet Alan Aday Sayısı",
      expressEntryCRS: "Davet Alan En Alt Sıradaki Adayın CRS Puanı",
      legalDisclaimerFswp:
        "Federal Skilled Worker (FSW) Altı Seçim Faktörü Puan Hesaplama Aracı, Northern Pathways Immigration Consulting Inc. tarafından bilgilendirme amaçlı geliştirilmiştir ve Immigration, Refugees and Citizenship Canada (IRCC) tarafından belirlenen Six Selection Factors kriterlerine dayanmaktadır. Bu araç IRCC ile ilişkili veya IRCC tarafından onaylı değildir. Amacı, göçmen adaylarına potansiyel uygunlukları hakkında genel bir rehberlik ve ön değerlendirme sunmaktır.",
      legalDisclaimerFswp2:
        "Hesaplayıcımız tarafından üretilen sonuçlar ile resmi Express Entry sisteminin sonuçları arasında herhangi bir farklılık olması durumunda, sistemin sonuçları geçerli olacaktır.",
      FSWPNavbar: "FSW Altı Seçim Faktörü Puan Hesaplama Aracı",
      FSWPTitle: "FSW Altı Seçim Faktörü",
      FSWPTitle2: "Puan Hesaplama Aracı",
      FSWPPopUpQuestion: "FSW Altı Seçim Faktörü Puan Hesaplama Aracı Nedir?",
      FSWPPopUpTitle: "FSW Altı Seçim Faktörü Puan Hesaplama Aracı",
      FSWPPopUpP1:
        "Federal Skilled Worker (FSW) Programı'na uygun olabilmek için ",
      FSWPPopUpP1Link: "genel uygunluk kriterlerini",
      FSWPPopUpP1Cont:
        "karşılamalı ve seçim faktörlerinde en az 67 puan almanız gerekmektedir.",
      FSWPPopUpP2:
        "FSW Programı uygunluğu için gerekli puanı alıp almadığınızı görmek için hesaplayıcımızı kullanabilirsiniz.",
      FSWPPopUpP3: "Federal Skilled Worker (FSW) programı,",
      FSWPPopUpP3Bold:
        "Kanada'nın Express Entry sistemindeki üç programdan biridir.",
      FSWPPopUpP3Cont:
        "Altı Seçim Faktörü Puan Hesaplama Aracı, bu programa uygunluğunuzu belirlemek için gereklidir.",
      FSWPPopUpP4:
        "Bu hesaplama aracı, potansiyel göçmenleri altı ana faktör üzerinden değerlendirir; uygun olabilmek için",
      FSWPPopUpP4Bold: "100 üzerinden minimum 67 puan",
      FSWPPopUpP4Cont: "gereklidir.",
      FSWPPopUpTitle2:
        "FSW Altı Seçim Faktörü Puan Hesaplama Aracı Nasıl Çalışır?",
      FSWPPopUpP5: "Puan Hesaplama Aracı şu faktörleri değerlendirir:",
      FSWPPopUpLi1Title: "Dil Yeterliliği (28 puan):",
      FSWPPopUpLi1:
        "İngilizce ve/veya Fransızca dil becerileriniz Kanada göçmenliği için çok önemlidir.",
      FSWPPopUpLi2Title: "Eğitim (25 puan):",
      FSWPPopUpLi2: "En yüksek eğitim seviyenize göre puan verilir.",
      FSWPPopUpLi3Title: "İş Deneyimi (15 puan): ",
      FSWPPopUpLi3:
        "İş deneyiminiz önemli bir faktördür; bu faktörden 15 puana kadar alınabilir.",
      FSWPPopUpLi4Title: "Yaş (12 puan): ",
      FSWPPopUpLi4: "Daha genç başvuru sahipleri daha yüksek puan alır.",
      FSWPPopUpLi5Title: "İş Teklifi (10 puan): ",
      FSWPPopUpLi5:
        "Kanadalı bir işverenden geçerli bir iş teklifi almanız puanınızı artırır.",
      FSWPPopUpLi6Title: "Uyum Faktörleri (10 puan): ",
      FSWPPopUpLi6:
        "Bu faktör, sizin ve eşinizin/common-law partnerinizin Kanada'ya ne kadar iyi uyum sağlayabileceğinizi değerlendirir.",
      FSWPPopUpP6:
        "Federal Skilled Worker (FSW) Programı için 67 veya daha fazla puan almak ve genel uygunluk kriterlerini karşılamak, bir Express Entry profili oluşturmanıza olanak tanır. ",
      FSWPPopUpP6Bold:
        "Ancak, uygunluğunuzun olması kalıcı oturum izni başvurusu daveti (ITA) almanızı garanti etmez.",
      FSWPPopUpP6Cont:
        "Havuzdaki sıralamanız, ITA alma şansınızı belirleyen Comprehensive System Ranking (CRS) puanınıza dayanmaktadır.",
      FSWPPopUpP7:
        "Kalıcı oturum başvurusu daveti alabilmek için, adayların CRS puanlarının davet turunun minimum puanından yüksek veya ona eşit olması gerekmektedir. Her turun minimum puanlarının farklılık gösterebileceğini unutmayın.",
      FSWPPopUpP8:
        "CRS puanınızı hesaplamak ve ITA alma şansınızı daha iyi anlamak için ",
      FSWPPopUpP8Bold: "CRS Puanı Hesaplama Aracımızı",
      FSWPPopUpP8Cont: "kullanabilirsiniz.",
      FSWPLanguageNote: "CLB 7 altı – Başvuru için uygunluğu bulunmuyor",
      FSWPWorkExperience: "İş Deneyimi",
      FSWPWorkExperienceP1:
        "Tam zamanlı ücretli çalışma deneyiminizin süresine (haftada en az 30 saat veya eşdeğer miktarda yarı zamanlı çalışma [örneğin, 24 ay boyunca haftada 15 saat]) göre puan kazanabilirsiniz. Bu deneyim, 2021 Ulusal Meslek Sınıflandırması (NOC) kapsamındaki Eğitim, Öğretim, Deneyim ve Sorumluluklar (TEER) kategorileri 0, 1, 2 veya 3'te listelenen bir nitelikli meslek olmalıdır.",
      FSWPWorkExperienceLiP1: "Puan almak için iş deneyiminiz:",
      FSWPWorkExperienceLi: "Kanada'da veya Kanada dışında olabilir.",
      FSWPWorkExperienceLi2: "Öğrenciyken kazanılmış olabilir.",
      FSWPWorkExperienceLi3:
        "Serbest meslek sahibi olarak elde edilmiş olabilir.",
      FSWPAdaptability: "Uyum Faktörleri",
      FSWPAdaptabilityTotal: "Uyum Faktörleri - Toplam",
      FSWPEducation:
        "Kanada içerisinde en az 2 akademik yıl tam zamanlı eğitimi (en az 2 yıllık bir programda) tamamladınız mı?",
      FSWPCanadianWorkExp: "Kanada İş Deneyimi",
      FSWPCanadianWorkP1:
        "NOC TEER 0, 1, 2 veya 3 kategorilerinde bir işte ve geçerli bir çalışma iznine sahip olarak.",
      FSWPSpouseEducationP1:
        "Eşiniz veya birlikte yaşadığınız partneriniz Kanada içerisinde en az 2 akademik yıl tam zamanlı eğitimi (en az 2 yıllık bir programda) tamamladı mı?",
      FSWPRelativesP1:
        "Siz veya eşiniz/common-law partneriniz, Kanada'da yaşayan, 18 yaşında veya daha büyük ve Kanada vatandaşı veya kalıcı oturum iznine sahip bir akrabaya sahip misiniz? Bu akraba aşağıdakilerden biri olmalıdır:",
      FSWPRelativesLi1: "Anne veya baba",
      FSWPRelativesLi2: "Büyükanne veya büyükbaba",
      FSWPRelativesLi3: "Çocuk",
      FSWPRelativesLi4: "Torun",
      FSWPRelativesLi5:
        "Sizin veya eşinizin kardeşi (sizin veya eşinizin ebeveyninin çocuğu)",
      FSWPRelativesLi6:
        "Sizin veya eşinizin amcası, halası, dayısı veya teyzesi (kan bağı veya evlilik yoluyla)",
      FSWPRelativesLi7: "Sizin veya eşinizin yeğeni",
      expressEntrySubcategory: "Alt Kategori",
      FSWPGeneral: "Genel",
      FSWPGeneralTotal: "Genel - Toplam",
    },
  },
};

// Check localStorage for the stored language, default to 'en' if not set
const storedLanguage = localStorage.getItem("selectedLanguage") || "en";

i18next.use(initReactI18next).init({
  resources,
  lng: storedLanguage,
  interpolation: {
    escapeValue: false,
  },
});

export default i18next;
